<template>
  <div>
    <q-input
      v-model="filterQuery"
      :label="$t('Form.Option.Search')"
      outlined
      dense
      square
      class="q-mb-sm"
      @input="filterFn"
    >
      <template v-slot:append>
        <q-icon name="search" />
      </template>
    </q-input>
    <div
      class="q-mb-sm"
      style="text-align: left; height: 500px; overflow: auto"
    >
      <table class="q-mb-md table-hover">
        <thead>
          <q-tr>
            <q-td
              v-for="(column, index) in columns"
              :key="column.field + index"
            >
              <span class="text-bold">{{ $t(column.label) }}</span>
            </q-td>
          </q-tr>
        </thead>
        <tbody>
          <q-tr v-for="(row, index) in filteredRows" :key="index">
            <q-td
              v-for="col in columns"
              :style="getSelectedStyle(row)"
              :key="index + col.field"
              @click="handleClick(row)"
            >
              <span class="ellipsis">{{ row[col.field] }}</span>
            </q-td>
          </q-tr>
        </tbody>
      </table>
    </div>
    <div
      class="q-pa-sm row items-center"
      style="
        border: 1px solid lightgray;
        min-height: 54px;
        max-height: 127px;
        overflow-y: auto;
      "
      id="selected-emails-div"
    >
      <span class="q-ma-sm">{{ $t("Form.Field.Procurer") }}:</span>
      <q-chip
        v-for="(item, index) in selected"
        :label="item.eName"
        removable
        :key="`chip-${index}`"
        @remove="handleRemove(item)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProEmailAddressBook",
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterQuery: "",
      selected: [],
      columns: [
        { label: "Form.Col.EnglishName", field: "eName" },
        { label: "Form.Col.ChineseName(Traditional)", field: "tName" },
        { label: "Form.Col.ChineseName(Simplified)", field: "cName" },
        { label: "Form.Col.EmailAddress", field: "email" },
        { label: "Form.Field.Department", field: "departmentName" },
      ],
      filteredRows: [],
    };
  },
  methods: {
    getSelectedStyle(item) {
      const index = this.selected.findIndex(
        (element) => element.hcmUserId === item.hcmUserId
      );
      return index !== -1 ? "background-color: #EBECF0;" : "";
    },

    handleRemove(item) {
      const index = this.selected.indexOf(item);
      this.selected.splice(index, 1);
    },

    handleClick(item) {
      const index = this.selected.indexOf(item);
      if (index === 0) {
        this.handleRemove(item);
      } else if (this.selected.length === 0) {
        this.selected.push(item);
      }

      const element = document.getElementById("selected-emails-div");
      element.scrollTop = element.clientHeight;
    },

    filterFn(needle) {
      this.filteredRows =
        needle === ""
          ? [...this.addressBookList]
          : (this.filteredRows = this.addressBookList.filter((value) =>
              needle
                .match(/[^ ,;]+/g)
                ?.every((query) =>
                  Object.entries(value).some(
                    (ele) =>
                      String(ele[1])
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) > -1
                  )
                )
            ));
    },
  },
  watch: {
    selected: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  mounted() {
    this.filteredRows = [...this.addressBookList];
  },
  computed: {
    addressBookList() {
      return this.list.reduce(
        (list, { departmentStr: department, id: hcmUserId, ...rest }) => {
          return [...list, { department, hcmUserId, ...rest }];
        },
        []
      );
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}

td,
th {
  border: 1px solid lightgray;
  padding: 2px 15px 2px 15px;
}

.table-hover tbody tr:hover td {
  cursor: pointer;
  background-color: #dddddd;
}
</style>
