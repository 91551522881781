<template>
  <div
    style="
      box-sizing: border-box;
      height: 100%;
      display: flex;
      padding-bottom: 18px;
    "
  >
    <el-container style="height: 100%; display: flex">
      <el-header style="height: 30px" class="wfm-first-header">
        <WFMBreadcrumb></WFMBreadcrumb>
      </el-header>

      <el-main style="display: flex">
        <el-table
          ref="datatable"
          :data="dtDelegateMenuList"
          v-loading="tableLoading"
          element-loading-background="rgba(255,255,255,0.5)"
          style="width: 100%; height: 100%; min-height: 50vh"
          border
          height="auto"
          show-overflow-tooltip
          @sort-change="sortAction"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" min-width="3"></el-table-column>

          <el-table-column
            :label="this.getRes('Delegate.ToUserId')"
            prop="dgEEUserCode"
            sortable="custom"
          >
            <div slot-scope="{ row }">
              <div>{{ row.dgEEUserCode }} - {{ row.dgEEName }}</div>
            </div>
          </el-table-column>

          <el-table-column
            prop="startdate"
            :formatter="formatDate"
            show-overflow-tooltip
            :label="this.getRes('Delegate.StartDate')"
            sortable="custom"
          ></el-table-column>

          <el-table-column
            prop="enddate"
            :formatter="formatDate"
            show-overflow-tooltip
            :label="this.getRes('Delegate.EndDate')"
            sortable="custom"
          ></el-table-column>

          <el-table-column
            prop="pc_menunames"
            :label="this.getRes('Delegate.DelegateMenuItem')"
            sortable="custom"
          ></el-table-column>

          <el-table-column :label="this.getRes('Delegate.ActionButton')">
            <template slot-scope="scope">
              <el-button
                :disabled="isAbled"
                @click.native.prevent="
                  EditDelegrateMenuDetail(scope.$index, scope.row)
                "
                type="text"
                size="small"
              >
                {{ cEditCaption }}
              </el-button>
              <el-button
                :disabled="isAbled"
                @click.native.prevent="
                  DeleteDelegrateMenuDetail(scope.$index, scope.row)
                "
                type="text"
                size="small"
              >
                {{ cDeleteCaption }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>

      <el-footer style="height: 33px">
        <el-row>
          <el-col :span="12">
            <el-button-group>
              <el-button
                type="primary"
                icon="el-icon-plus"
                :disabled="isAbled"
                @click="AddDelegrateMenuDetail"
                >{{ this.getRes("Delegate.Add") }}
              </el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                :disabled="isAbled"
                @click="DeleteAllSelectedDelegateMenu"
                >{{ this.getRes("Delegate.Delete") }}
              </el-button>
            </el-button-group>
          </el-col>
          <el-col :span="12">
            <el-pagination
              small
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :total="total"
              layout="total, prev, pager, next"
            >
            </el-pagination>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
    <!-- 新增弹框 -->
    <el-dialog
      QA="/modules/MyInfoManagement/DelegateSetting/DelegateMenu/DelegateMenu_1622781511981_0"
      v-if="bShowDelegrateMenuDetail"
      :title="cDelegateMenuDetailTitle"
      :visible.sync="bShowDelegrateMenuDetail"
      width="50%"
      height="80%"
      :before-close="HandleDelegrateMenuDetailClose"
      :close-on-press-escape="closeescape"
      @opened="HandleDelegrateMenuDetailOpened"
      v-dialogDrag
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <div class="text item">
          <div class="wf-common-title newstext">
            <el-row>
              <el-col>
                <el-form-item
                  :label="this.getRes('Delegate.ToUserId')"
                  label-width="100px"
                  prop="toUserData"
                >
                  <pro-select-procurer-user
                    ref="SelectUser"
                    v-model="form.cSelectedToUserID"
                    :list="userlist"
                  />
                  <!--                  <selectuserfield-->
                  <!--                    ref="SelectUser"-->
                  <!--                    v-model="toUserData"-->
                  <!--                    :showName="bShowName"-->
                  <!--                    @ok="handelSelectedToUserIDResult"-->
                  <!--                    :readonly="bSelectUserReadOnly"-->
                  <!--                    class="group-management-selectuserfield"-->
                  <!--                    :showDeleteButton="bShowDelUserBtn"-->
                  <!--                    InputResultTextWidth="width: 300px"-->
                  <!--                    :showAll="false"-->
                  <!--                    :ShowAllFlag="false"-->
                  <!--                    :SelectOne="true"-->
                  <!--                    @SelectedUserName="handelSelectedToUserName"-->
                  <!--                  >-->
                  <!--                  </selectuserfield>-->
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="14">
                <div class="grid-content">
                  <el-form-item
                    :label="this.getRes('Delegate.StartDate')"
                    label-width="100px"
                    prop="dDgStartDate"
                  >
                    <wfm-date-picker
                      v-model="form.dDgStartDate"
                      type="datetime"
                      :format="cDateFormat"
                      :disabled="readonly"
                      style="width: 365px"
                    ></wfm-date-picker>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="14">
                <div class="grid-content">
                  <el-form-item
                    :label="this.getRes('Delegate.EndDate')"
                    label-width="100px"
                    prop="dDgEndDate"
                  >
                    <wfm-date-picker
                      v-model="form.dDgEndDate"
                      type="datetime"
                      :format="cDateFormat"
                      :disabled="readonly"
                      style="width: 365px"
                    ></wfm-date-picker>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="14">
                <div class="grid-content">
                  <el-form-item
                    :label="this.getRes('Delegate.DelegateMenuItem')"
                    label-width="100px"
                    prop="cSelectedDelegateMenuName"
                  >
                    <el-input
                      readonly
                      v-model="form.cSelectedDelegateMenuName"
                      style="max-width: 300px"
                      :disabled="readonly"
                    ></el-input>
                    <!--                    <el-button-->
                    <!--                      style="margin-left: 11px"-->
                    <!--                      icon="el-icon-more"-->
                    <!--                      @click="ShowDelegateMenuSelect"-->
                    <!--                      :disabled="readonly || form.bSelectAllDelegateMenu"-->
                    <!--                    ></el-button>-->
                    <q-btn
                      color="primary"
                      icon="add"
                      outline
                      style="height: 40px; border-radius: 0; margin-left: 11px"
                      @click="ShowDelegateMenuSelect"
                      :disabled="readonly || form.bSelectAllDelegateMenu"
                    />
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="1" style="margin-left: 20px">
                <div
                  class="grid-content"
                  style="height: 40px; line-height: 40px"
                >
                  <el-checkbox
                    v-model="form.bSelectAllDelegateMenu"
                    @change="ChangeSelectAllDelegateMenu"
                    >{{ this.getRes("Delegate.SelectAllDelegateMenu") }}
                  </el-checkbox>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <!-- 第一次弹框 -->
        <el-button
          type="primary"
          :disabled="disableBtn"
          v-un-click-repeat
          @click="HandleSave('form')"
          >{{ this.getRes("save") }} </el-button
        >&nbsp;
        <el-button :disabled="disableBtn" @click="HandleCancel('form')"
          >{{ this.getRes("cancel") }} </el-button
        >&nbsp;
      </div>
    </el-dialog>

    <el-dialog
      QA="/modules/MyInfoManagement/DelegateSetting/DelegateMenu/DelegateMenu_1622781511981_1"
      :title="cSelectDelegateMenuTitle"
      :visible.sync="bShowSelectDelegateMenuDetail"
      width="50%"
      height="80%"
      @opened="HandleDelegateMenuSelectOpened"
      @close="HandleDelegateMenuSelectClose"
      v-dialogDrag
      :close-on-click-modal="false"
    >
      <el-form ref="formDelegateMenu">
        <div class="text item">
          <div class="wf-common-title newstext">
            <el-tree
              :data="oDelegateMenuItem"
              :props="defaultProps"
              show-checkbox
              node-key="menuid"
              ref="DelegateMenuItemTree"
            ></el-tree>
          </div>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <!-- 选择菜单的二次弹框 -->
        <el-button type="primary" @click="HandleDelegateMenuSelectOk"
          >{{ this.getRes("save") }} </el-button
        >&nbsp;
        <el-button @click="HandleDelegateMenuSelectClose"
          >{{ this.getRes("cancel") }} </el-button
        >&nbsp;
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ProSelectProcurerUser from "@/components/PROSmart/ProSelectProcurerUser.vue";

export default {
  name: "ApprovalList",
  components: { ProSelectProcurerUser },
  data() {
    var __CheckSelectedToUser = (rule, value, callback) => {
      var me = this;

      console.log(
        "%c%s me.form.cSelectedToUserID",
        "color: #a50aa5;font-weight: bold;",
        "[Andy Debug]",
        me.form.cSelectedToUserID
      );

      if (me.form.cSelectedToUserID === "") {
        callback(new Error(this.getRes("Delegate.Error.ToUserIdEmpty")));
      }
      callback();
    };
    var __CheckDgStartDate = (rule, value, callback) => {
      var me = this;
      if (me.form.dDgStartDate == null || me.form.dDgStartDate === "") {
        callback(new Error(this.getRes("Delegate.Error.StartDateEmpty")));
      }
      callback();
    };
    var __CheckDgEndDate = (rule, value, callback) => {
      var me = this;
      if (me.form.dDgEndDate == null || me.form.dDgEndDate === "") {
        callback(new Error(this.getRes("Delegate.Error.EndDateEmpty")));
      } else {
        if (me.form.dDgStartDate != null && me.form.dDgStartDate != "") {
          if (new Date(me.form.dDgStartDate) > new Date(me.form.dDgEndDate)) {
            callback(
              new Error(this.getRes("Delegate.Error.TimePeriodInvalid"))
            );
          }
        }
      }
      callback();
    };
    var __CheckDelegateMenu = (rule, value, callback) => {
      var me = this;
      if (
        me.form.cSelectedDelegateMenuID === "" &&
        !me.form.bSelectAllDelegateMenu
      ) {
        callback(new Error(this.getRes("Delegate.Error.MenuItemEmpty")));
      }
      callback();
    };
    return {
      dtDelegateMenuList: [],
      cHostURL: "",
      cLanguage: "",
      currentPage: 1,
      pageSize: 15,
      thisPage: 1,
      thisPageSize: 15,
      selectedRecRow: [],
      total: 0,
      disableBtn: false,
      isAbled: false,
      orderField: "startdate desc",
      cDateFormat: "",
      cEditCaption: this.getRes("Delegate.Edit"),
      cDeleteCaption: this.getRes("Delegate.Delete"),
      bShowDelegrateMenuDetail: false,
      cDelegateMenuDetailTitle: "",
      cSelectDelegateMenuTitle: this.getRes("Delegate.DelegateMenuSelect"),
      readonly: false,
      bSelectUserReadOnly: false,
      bShowDelUserBtn: false,
      bShowName: true,
      bShowSelectDelegateMenuDetail: false,
      oDelegateMenuItem: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      form: {
        toUserData: new Array(),
        cRecordId: "",
        cSelectedToUserID: "",
        cSelectedToUserName: "",
        dDgStartDate: new Date(),
        dDgEndDate: new Date(),
        bEnabled: true,
        cDelegateType: "",
        cSelectedDelegateMenuID: "",
        cSelectedDelegateMenuName: "",
        bSelectAllDelegateMenu: false,

        displaysequence: "",
      },
      rules: {
        toUserData: [
          {
            required: true,
            trigger: "change",
            validator: __CheckSelectedToUser,
          },
        ],
        dDgStartDate: [
          { required: true, trigger: "blur", validator: __CheckDgStartDate },
        ],
        dDgEndDate: [
          { required: true, trigger: "blur", validator: __CheckDgEndDate },
        ],
        cSelectedDelegateMenuName: [
          { required: true, trigger: "change", validator: __CheckDelegateMenu },
        ],
      },
      bAddNewRecord: false,
      createuser: "",
      createtime: null,
      lastmodifyuser: "",
      lastmodifytime: null,
      tableLoading: true,
      canDelClick: true,
      closeescape: true,
      userlist: [],
    };
  },

  watch: {},
  created() {
    this.loadAddressBookList();
  },
  mounted: function () {
    var me = this;

    //me.ShowPreloader();
    me.pageSize = me.LocalUser.pageSize;
    me.thisPageSize = me.LocalUser.pageSize;
    me.cHostURL = window.Lark.ServiceAjax.getHost();
    me.cLanguage = localStorage.getItem("Language");
    me.dtDelegateMenuList = [];

    me.setDateFormat();

    //me.LoadMenuItem();
    me.loadDelegrateMenuList();
    me.HidePreloader();
  },

  methods: {
    /*表格排序方法*/
    sortAction(column) {
      if (column.prop == null) {
        return;
      }
      // this.showLoading = true;
      var vsort = column.order == "ascending" ? "asc" : "desc";
      if (column.prop == "pc_menunames") {
        this.orderField = "pc_menuids" + "  " + vsort;
      } else {
        this.orderField = column.prop + "  " + vsort;
      }

      this.loadDelegrateMenuList();
    },

    formatDate: function (row, column) {
      var date = row[column.property];

      return this.toDateFormat(date);
    },
    formatDateNoTime: function (row, column) {
      var date = row[column.property];

      return this.toDateFormatNoTime(date);
    },
    toDateFormat(ldValue) {
      var lcDateFormat = this.LocalUser.DateFormat;

      if (typeof ldValue == "undefined") {
        return "";
      } else {
        return ldValue.dateFormat(lcDateFormat + " H:i");
      }
    },
    toDateFormatNoTime(ldValue) {
      var lcDateFormat = this.LocalUser.DateFormat;

      if (typeof ldValue == "undefined") {
        return "";
      } else {
        return ldValue.dateFormat(lcDateFormat);
      }
    },

    loadDelegrateMenuList() {
      var me = this;
      var start = me.thisPage - 1;
      var limit = me.thisPageSize;
      var userid = me.LocalUser.UserId;

      me.dtDelegateMenuList = [];
      me.invokeService(
        "Delegate",
        "GetAllowDelegatePCMenu_All",
        [],
        function (msg) {
          if (msg.ReturnData.$.Success) {
            var models = msg.ReturnData.$.ReturnData.toJson();

            for (var iModCnt = 0; iModCnt < models.length; iModCnt++) {
              var menuItem = models[iModCnt];

              menuItem["name"] = me.getRes(menuItem.rkey);
            }

            me.oDelegateMenuItem = models;

            me.invokeService(
              "Delegate",
              "GetDelegateMenuInfo",
              [userid, start, limit, me.orderField],
              function (msg) {
                var data = msg.ReturnData.$.ReturnData.toJson();

                for (var iRecCnt = 0; iRecCnt < data.length; iRecCnt++) {
                  var oCurMenuRec = data[iRecCnt];

                  var cCurPcMenuId = oCurMenuRec.pc_menuids
                    .toString()
                    .trim()
                    .toUpperCase();
                  var cCurPcMenuName = "";

                  if (cCurPcMenuId == "" || cCurPcMenuId == "ALL") {
                    cCurPcMenuName = me.getRes("Delegate.AllItem");
                  } else {
                    var arrPcMenuId = cCurPcMenuId.split(";");
                    for (
                      var iArrCnt = 0;
                      iArrCnt < arrPcMenuId.length;
                      iArrCnt++
                    ) {
                      for (
                        var iMItmCnt = 0;
                        iMItmCnt < me.oDelegateMenuItem.length;
                        iMItmCnt++
                      ) {
                        if (
                          arrPcMenuId[iArrCnt] ==
                          me.oDelegateMenuItem[iMItmCnt].menuid.toString()
                        ) {
                          if (cCurPcMenuName != "") {
                            cCurPcMenuName = cCurPcMenuName + ";";
                          }
                          cCurPcMenuName =
                            cCurPcMenuName +
                            me.oDelegateMenuItem[iMItmCnt].name;
                        }
                      }
                    }
                  }

                  oCurMenuRec["pc_menunames"] = cCurPcMenuName;
                }

                if (data.length >= 0) {
                  me.total = msg.ReturnData.$.ReturnData.totalCount;
                }
                this.tableLoading = false;
                me.dtDelegateMenuList = data;

                //me.setSelected();
              },
              function (msg) {
                console.log(msg);

                this.tableLoading = false;
              }
            );
          }
          me.isAbled = false;
        },
        function (msg) {
          console.log(msg);

          me.isAbled = false;
        }
      );
    },

    handleCurrentChange(val) {
      this.thisPage = val;
      this.loadDelegrateMenuList();
    },

    handleSelectionChange(val) {
      this.canDelClick = true;
      this.selectedRecRow = val;
    },
    loadAddressBookList() {
      this.$proSmart.formBuilder
        .getAddressBookList(this)
        .then(({ userList, departmentList }) => {
          /* Set address book list */
          this.userlist = userList
            .map(({ departmentStr, ...rest }) => ({
              ...rest,
              name: rest.eName,
              departmentName: this.$t(
                departmentList.find(({ code }) => code === departmentStr)?.name
              ),
            }))
            .filter((b) => b.code !== this.LocalUser.UserCode);
        });
    },
    AddDelegrateMenuDetail() {
      var me = this;
      //var userid = me.LocalUser.UserId;

      me.bAddNewRecord = true;

      me.cDelegateMenuDetailTitle = this.getRes("Delegate.AddMenuDelegatee");

      me.form.cSelectedToUserID = "";

      me.form.toUserData = [];
      me.form.toUserData.push(me.form.cSelectedToUserID);
      /*
      if (me.$refs.SelectUser != undefined) {
          me.$refs.SelectUser.resultText = '';
          //me.$refs.SelectUser.value = '';
      }
      */
      me.form.cRecordId = "";
      me.form.cSelectedToUserName = "";
      me.form.dDgStartDate = new Date();
      me.form.dDgEndDate = new Date();
      me.form.bEnabled = true;
      me.form.cDelegateType = "1";

      me.form.cSelectedDelegateMenuID = "";
      me.form.cSelectedDelegateMenuName = "";
      me.form.bSelectAllDelegateMenu = false;

      me.bShowDelegrateMenuDetail = true;
    },

    EditDelegrateMenuDetail(index, item) {
      var me = this;
      me.isAbled = true;
      //var userid = me.LocalUser.UserId;
      var cRecordId = item.id;
      me.bAddNewRecord = false;

      me.cDelegateMenuDetailTitle = this.getRes("Delegate.EditMenuDelegatee");

      me.invokeService(
        "Delegate",
        "GetDelegateMenuById",
        [cRecordId],
        function (msg) {
          this.isAbled = false;
          if (msg.ReturnData.$.Success) {
            var data = msg.ReturnData.$.Data.toJson();

            me.form.cSelectedToUserID = data[0].delegatetouserid;

            me.form.toUserData = [];
            me.form.toUserData.push(me.form.cSelectedToUserID);

            me.form.cRecordId = data[0].id;
            me.form.cSelectedToUserName = data[0].dgEEName;
            /*
            if (me.$refs.SelectUser != undefined) {
                me.$refs.SelectUser.resultText = me.form.cSelectedToUserName;
            }
            */
            me.form.dDgStartDate = data[0].startdate;
            me.form.dDgEndDate = data[0].enddate;
            me.form.bEnabled = data[0].enabled;
            me.form.cDelegateType = data[0].delegatetype.toString().trim();

            if (data[0].pc_menuids.toUpperCase() == "ALL") {
              me.form.cSelectedDelegateMenuID = "ALL";
              me.form.cSelectedDelegateMenuName = "";
              me.form.bSelectAllDelegateMenu = true;
            } else {
              me.form.cSelectedDelegateMenuID = data[0].pc_menuids;
              me.form.cSelectedDelegateMenuName = "";

              var arrTmpMenuID = me.form.cSelectedDelegateMenuID.split(";");

              for (var iMIDCnt = 0; iMIDCnt < arrTmpMenuID.length; iMIDCnt++) {
                var cCurMenuID = arrTmpMenuID[iMIDCnt].trim().toLowerCase();

                if (me.oDelegateMenuItem != null) {
                  for (
                    var iMenuCnt = 0;
                    iMenuCnt < me.oDelegateMenuItem.length;
                    iMenuCnt++
                  ) {
                    var cChkMenuID = me.oDelegateMenuItem[iMenuCnt].menuid
                      .toString()
                      .toLowerCase();
                    var cChkMenuName = me.oDelegateMenuItem[iMenuCnt].name;

                    if (cChkMenuID == cCurMenuID) {
                      if (me.form.cSelectedDelegateMenuName.trim() != "") {
                        me.form.cSelectedDelegateMenuName =
                          me.form.cSelectedDelegateMenuName + ";";
                      }

                      me.form.cSelectedDelegateMenuName =
                        me.form.cSelectedDelegateMenuName + cChkMenuName;
                    }
                  }
                }
              }

              me.form.bSelectAllDelegateMenu = false;
              // me.$nextTick(function() {
              //     me.SetDelegateMenuItem();
              //     //me.HandleDelegateMenuSelectOk();
              // });
            }

            me.createuser = data[0].createuser;
            me.createtime = data[0].createtime;
            me.lastmodifyuser = data[0].lastmodifyuser;
            me.lastmodifytime = data[0].lastmodifytime;

            me.bShowDelegrateMenuDetail = true;
          } else {
            var message = msg.ReturnData.$.Data;
            me.alert(message);
          }
        },
        function (msg) {
          console.log(msg);

          this.isAbled = false;
          me.showError(this.getRes("Delegate.Error.LoadDataTable"));
        }
      );
    },

    DeleteDelegrateMenuDetail(index, item) {
      var me = this;
      //var userid = me.LocalUser.UserId;
      var cRecordId = item.id;

      var msg = me.getRes("Delegrate.ConfirmDeleteRecord");
      me.confirm(msg, me.getRes("comfirm"), function () {
        this.isAbled = true;
        me.invokeService(
          "Delegate",
          "DeleteDelegateMenu",
          [cRecordId],
          function (msg) {
            this.isAbled = false;
            if (msg.ReturnData.$.Success) {
              if (me.dtDelegateMenuList.length <= 1 && me.thisPage > 1) {
                me.thisPage--;
              }
              me.loadDelegrateMenuList();
            } else {
              var message = msg.ReturnData.$.Data;
              me.alert(message);
            }
          },
          function (msg) {
            console.log(msg);

            this.isAbled = false;
            me.showError(this.getRes("Delegate.Error.LoadDataTable"));
          }
        );
      });
    },

    HandleDelegrateMenuDetailClose() {
      if (this.disableBtn) {
        return;
      }
      this.bShowDelegrateMenuDetail = false;
      this.ClearAllValidateResult("form");
    },

    HandleCancel(formName) {
      console.log(formName);

      this.HandleDelegrateMenuDetailClose();
    },

    setDateFormat() {
      var me = this;
      var strFormat = me.LocalUser.DateFormat;
      me.cDateFormat = "yyyy/MM/dd";

      if (strFormat == "d/m/Y") {
        me.cDateFormat = "dd/MM/yyyy";
      } else if (strFormat == "m/d/Y") {
        me.cDateFormat = "MM/dd/yyyy";
      }

      me.cDateFormat = me.cDateFormat + " HH:mm";
    },

    handelSelectedToUserIDResult(val) {
      console.log(
        "%c%s val",
        "color: #a50aa5;font-weight: bold;",
        "[Andy Debug]",
        val
      );

      this.form.cSelectedToUserID = val;
    },

    handelSelectedToUserName(val) {
      this.form.cSelectedToUserName = val;
    },

    LoadMenuItem() {
      var me = this;
      //var userid = me.LocalUser.UserId;

      me.invokeService(
        "Delegate",
        "GetAllowDelegatePCMenu_All",
        [],
        function (msg) {
          if (msg.ReturnData.$.Success) {
            var models = msg.ReturnData.$.ReturnData.toJson();

            for (var iModCnt = 0; iModCnt < models.length; iModCnt++) {
              var menuItem = models[iModCnt];

              menuItem["name"] = me.getRes(menuItem.rkey);
            }

            me.oDelegateMenuItem = models;
          }
        },
        function (msg) {
          console.error("error GetAllowDelegatePCMenu_All");
          console.error(msg);
        }
      );
    },

    ChangeSelectAllDelegateMenu() {
      var me = this;

      if (me.form.bSelectAllDelegateMenu) {
        me.form.cSelectedDelegateMenuID = "ALL";
        me.form.cSelectedDelegateMenuName = "";
        me.$refs["form"].clearValidate("cSelectedDelegateMenuName");
      } else {
        me.HandleDelegateMenuSelectOk();
      }
    },

    SetDelegateMenuItem() {
      var selectedArray = this.form.cSelectedDelegateMenuID.split(";");
      this.$refs.DelegateMenuItemTree.setCheckedKeys(selectedArray);
    },

    ShowDelegateMenuSelect() {
      this.bShowSelectDelegateMenuDetail = true;
    },

    HandleDelegateMenuSelectOpened() {
      this.SetDelegateMenuItem();
      //me.HandleDelegateMenuSelectOk();
    },

    HandleDelegateMenuSelectOk() {
      var me = this;

      var strPCMenuId = "";
      var strPCMenuName = "";

      if (me.$refs.DelegateMenuItemTree != null) {
        var arrPCMenu = me.$refs.DelegateMenuItemTree.getCheckedNodes().concat(
          me.$refs.DelegateMenuItemTree.getHalfCheckedNodes()
        );

        if (arrPCMenu.length > 0) {
          strPCMenuId = arrPCMenu[0].menuid;
          strPCMenuName = arrPCMenu[0].name;

          for (var i = 1; i < arrPCMenu.length; i++) {
            strPCMenuId = strPCMenuId + ";" + arrPCMenu[i].menuid.toString();
            strPCMenuName = strPCMenuName + ";" + arrPCMenu[i].name;
          }
        }
      }
      me.form.cSelectedDelegateMenuID = strPCMenuId;
      me.form.cSelectedDelegateMenuName = strPCMenuName;

      me.bShowSelectDelegateMenuDetail = false;
    },

    HandleDelegateMenuSelectClose() {
      var me = this;

      // me.selectedRow = [];

      // me.isFinshChangeValue=true;

      // me.isFinshChangeValue=false;
      me.bShowSelectDelegateMenuDetail = false;
      //me.showSelected = false;
    },

    HandleDelegrateMenuDetailOpened() {
      var me = this;

      me.form.toUserData = [];

      if (me.bAddNewRecord) {
        me.form.cSelectedToUserID = "";
        me.$refs.SelectUser.resultText = "";
        //me.$refs.SelectUser.value = '';
      } else {
        me.$refs.SelectUser.resultText = me.form.cSelectedToUserName;
      }

      me.form.toUserData.push(me.form.cSelectedToUserID);
    },

    HandleSave(formName) {
      var me = this;
      me.$refs[formName].validate((valid) => {
        if (valid) {
          // if(!me.checkForm()){
          //     return;
          // }
          var method = "";
          var params = [];

          me.createuser = me.LocalUser.UserId;
          me.lastmodifyuser = "";
          me.createtime = new Date();
          me.lastmodifytime = new Date();

          if (!me.bAddNewRecord) {
            method = "UpdateDelegateMenu";
            me.lastmodifyuser = me.LocalUser.UserId;
            me.lastmodifytime = new Date();

            params = [
              me.form.cRecordId,
              me.LocalUser.UserId,
              me.form.cSelectedToUserID,
              me.form.dDgStartDate,
              me.form.dDgEndDate,
              me.form.bEnabled,
              me.form.cSelectedDelegateMenuID.toString(),
              me.form.cDelegateType.toString().trim(),
              me.createuser,
              me.createtime,
              me.lastmodifyuser,
              me.lastmodifytime,
            ];
          } else {
            method = "InsertDelegateMenu";

            params = [
              me.LocalUser.UserId,
              me.form.cSelectedToUserID,
              me.form.dDgStartDate,
              me.form.dDgEndDate,
              me.form.bEnabled,
              me.form.cSelectedDelegateMenuID.toString(),
              me.form.cDelegateType.toString().trim(),
              me.createuser,
              me.createtime,
              me.lastmodifyuser,
              me.lastmodifytime,
            ];
          }
          me.disableBtn = true;
          me.closeescape = false;
          me.invokeService(
            "Delegate",
            method,
            params,
            function (msg) {
              if (msg.ReturnData.$.Success) {
                me.showSuccess(me.getRes("savesuccess"));
                //me.loadTableData();
                me.bShowDelegrateMenuDetail = false;
                me.HandleDelegrateMenuDetailClose();
                me.loadDelegrateMenuList();
              } else {
                var message = msg.ReturnData.$.Data;
                me.alert(message);
              }
              this.disableBtn = false;
              this.closeescape = true;
            },
            function (msg) {
              var message = me.getRes("optfailed");
              me.disableBtn = false;
              me.closeescape = true;
              me.alert(message);
              console.log(msg);
            }
          );
          return true;
        } else {
          this.disableBtn = false;
          this.closeescape = true;
          console.error("error submit!!");
          return false;
        }
      });
    },

    DeleteAllSelectedDelegateMenu() {
      var me = this;
      var cDelRowIdList = "";

      //me.selectedRecRow.foreach(oCurRow => {
      //    arrSelect.push(oCurRow.id);
      //});

      if (me.selectedRecRow.length > 0) {
        cDelRowIdList = me.selectedRecRow[0].id.toString();

        for (var iRecCnt = 1; iRecCnt < me.selectedRecRow.length; iRecCnt++) {
          cDelRowIdList =
            cDelRowIdList + "," + me.selectedRecRow[iRecCnt].id.toString();
        }
      }
      if (!me.canDelClick) return;
      if (cDelRowIdList == "") {
        me.canDelClick = false;
        me.showError(
          this.getRes("Delegate.Error.NoRecSelectedForDelete"),
          function () {
            me.canDelClick = true;
          }
        );
        return;
      }
      var msg = me.getRes("Delegrate.ConfirmDeleteRecord");

      me.confirm(msg, me.getRes("comfirm"), function () {
        me.isAbled = true;
        me.invokeService(
          "Delegate",
          "BatchDeleteDelegateMenu",
          [cDelRowIdList],
          function (msg) {
            if (msg.ReturnData.$.Success) {
              if (
                me.selectedRecRow.length === me.dtDelegateMenuList.length &&
                this.thisPage > 1
              ) {
                this.thisPage--;
              }
              me.loadDelegrateMenuList();
            } else {
              var message = msg.ReturnData.$.Data;
              me.alert(message);
            }
          },
          function (msg) {
            console.log(msg);

            me.showError(this.getRes("Delegate.Error.LoadDataTable"));
          }
        );
      });
    },

    ClearAllValidateResult(formName) {
      var me = this;

      if (me.$refs[formName] != null) {
        me.$refs[formName].clearValidate();
      }
    },
  },
};
</script>
