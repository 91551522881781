<template>
  <div>
    <div class="row no-wrap" style="width: 100%; min-height: 42px">
      <div class="col-auto q-mr-sm" style="width: 300px">
        <el-input
          readonly
          v-model="userName"
          style="max-width: 300px"
          :placeholder="$t('SelectUserField.placeholderInfo')"
        ></el-input>
      </div>
      <div class="col-auto q-mr-sm">
        <pro-email-address-book-dialog
          v-model="isOpen"
          :list="list"
          @select="handleSelect"
        />
        <q-btn
          color="primary"
          icon="add"
          outline
          style="height: 40px; border-radius: 0"
          @click="isOpen = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProEmailAddressBookDialog from "@/components/PROSmart/ProEmailAddressBookDialog.vue";

export default {
  name: "ProSelectProcurerUser",
  components: { ProEmailAddressBookDialog },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
      userName: "",
    };
  },
  methods: {
    /**
     * @param {Array<{cName: string, code: string, department: string, departmentName:string, eName:string, email:string, groups: string, hcmUserId:string, name:string, tName:string}>}arr
     */
    handleSelect(arr) {
      if (arr.length === 0) {
        this.userName = "";
      }

      this.userName = arr[0].eName;
      this.$emit("input", arr[0].hcmUserId);
      // console.log(
      //   '%c%s arr',
      //   'color: #a50aa5;font-weight: bold;',
      //   '[Andy Debug]',
      //   arr
      // );
    },
  },
};
</script>
