<template>
  <q-dialog v-model="isOpen" full-width>
    <q-card>
      <q-toolbar>
        <q-toolbar-title>
          <h6 class="text-primary">{{ $t("menu.SearchProcurer") }}</h6>
        </q-toolbar-title>
        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-separator />
      <q-card-section>
        <pro-email-address-book v-model="dialogSelected" :list="list" />
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <q-btn
          no-caps
          color="primary"
          v-close-popup
          @click="handleSubmit"
          :label="$t('System.Button.Add')"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import ProEmailAddressBook from "@/components/PROSmart/ProEmailAddressBook.vue";

export default {
  name: "ProEmailAddressBookDialog",
  components: { ProEmailAddressBook },
  props: {
    value: {
      type: Boolean,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogSelected: [],
      isOpen: this.value,
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("select", this.dialogSelected);
      this.dialogSelected = [];
    },
  },
  watch: {
    isOpen: {
      handler(val) {
        this.$emit("input", val);
      },
    },

    value: {
      handler(val) {
        this.isOpen = val;
      },
    },
  },
};
</script>
